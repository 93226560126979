const userInfo = JSON.parse(localStorage.getItem('userInfo'));
const user_id= userInfo?userInfo.user_id : 0
const org_type= userInfo?userInfo.org_type : 0
const userList = [487,321,1111174]
const articleapplisList = [487,256,1111174] //申请发货功能
const uploadReports = [487,524,1111174] //上传质检单
const OrderDelivery = [487,493,1111174] //订单发货
const insFule = [487,529,1111174] //仪器数据上传
const styleStandard = [487,1111174] //样式规法



let children6 = [
    
]

OrderDelivery.indexOf(user_id) !== -1 && children6.push({
    name: '订单发货',
    path: '/otherOrderDelivery',
},)


+
uploadReports.indexOf(user_id) !== -1 && children6.push({
    name: '质检单上传',
    path: '/uploadQualityTesting',
},)
userList.indexOf(user_id) !== -1 && children6.push({
    name: 'app版本管理',
    path: '/appVersion',
},)
userList.indexOf(user_id) !== -1 && children6.push({
    name: '强制发货',
    path: '/forcedDelivery',
},)
insFule.indexOf(user_id) !== -1 && children6.push({
    name: '仪器数据上传 ',
    path: '/insFile',
},)
userList.indexOf(user_id) !== -1 && children6.push({
    name: '创建账号',
    path: '/createAccount',
},)
userList.indexOf(user_id) !== -1 && children6.push({
    name: '创建医院',
    path: '/createHospital',
},)
userList.indexOf(user_id) !== -1 && children6.push({
    name: '盘点',
    path: '/check',
},)
styleStandard.indexOf(user_id) !== -1 && children6.push({
    name: '样式规范',
    path: '/styleStandard',
},)
userList.indexOf(user_id) !== -1 && children6.push({
    name: '行业动态',
    path: '/industryDynamicsDelivery',
},)



let children3 = [
    {
        name: '室内质控',
        path: 'Indoor',
    },
    {
        name: '室间一致性评价',
        path: 'Between',
    },
]
org_type != 2 && children3.unshift({
    name: '质控面板',
    path: 'Charge',
},)


let menuList = [
    {
        name: '首页',
        icon: require('@/assets/img/home.png'),
        img: require('@/assets/img/seek.png'),
        path: '/shouye',
        children: [],
    },
    {
        name: '搜索',
        icon: require('@/assets/img/seachtb.png'),
        img: require('@/assets/img/cloud.png'),
        path: '/SearchPage',
        children: [],
    },
    {
        name: '云质控',
        icon: require('@/assets/img/yunzhikong.png'),
        img: require('@/assets/img/cloud.png'),
        children: children3,
    },
    {
        name: '统计',
        icon: require('@/assets/img/tongji.png'),
        img: require('@/assets/img/statistics.png'),
        children: [
            {
                name: '用量统计',
                path: '/Dosage',
            },
            {
                name: '销量统计',
                path: '/Sales',
            },
            {
                name: '维修统计',
                path: '/Maintain',
            },
            {
                name: '备件统计',
                path: '/sparePart',
            },
            {
                name: '地区炎症感染趋势',//样本检测统计
                path: '/Samples',
            },
        ],
    },

    {
        name: '其他',
        icon: require('@/assets/img/qttb.png'),
        img: require('@/assets/img/rest.png'),
        children: children6,
    },
    {
        name: '管理',
        icon: require('@/assets/img/qttb.png'),
        img: require('@/assets/img/rest.png'),
        children: [
            {
                name: '用户管理',
                path: '/userManage',
            },
            {
                name: '菜单管理',
                path: '/menuManage',
            },
            {
                name: '角色管理',
                path: '/roleManage',
            },
        ],
    },
]

// 申请发货功能
articleapplisList.indexOf(user_id) != -1 && menuList.push({
    name: '申请',
        icon: require('@/assets/img/shipment.png'),
        img: require('@/assets/img/apply.png'),
        children: [
            {
                name: '审批物品申请',
                path: '/ApproveArticle',
            },
        ],
},)

org_type != 1 && menuList.shift();

export {
    menuList
}