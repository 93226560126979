<template>
  <div>
    <div class="login">
      <div class="head">
        <div class="headTop">
          <div class="header-logo">
            <img src="@/assets/img/logo.png" alt="" />
            <div class="line" />
            <div class="designation">欢迎您来到 <br>腾云管家大数据SaaS服务系统</div>
            <headerv />
          </div>
        </div>
      </div>
      <div class="headMenu">
        <div class="menuItem" v-for="(item, index) of menuList"
        :key="index">
          <div class="menuItem-box" @click="toPage(item)">
            <img :src="item.img" alt="" />
            <div>
              <div class="menuItem-name">{{ item.name }}</div>
              <div class="straight">
                <img src="@/assets/img/straight.png" alt="" />
              </div>
            </div>
         </div>
        </div>
      </div>
      <!-- <div class="header_right">
        <div class="avatar">
          <el-avatar
            src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            class="el-avatar"
          />
        </div>
        <div class="avatarname">{{ user_name }}</div>

        <div class="notice">
          <div class="noticeOne">欢迎您来到</div>
          <div class="noticeTwo">腾云管家大数据SaaS服务系统</div>
        </div>
      </div> -->
    </div>
    <div class="below">
      <!-- 左边栏路由导航 -->
      <!-- <div class="searchBar">
        <div
          class="searchBarone"
          v-for="(item, index) of menuList"
          :key="index"
        >
          <div class="cloud cursorpointer" @click="toPage(item)">
            <img :src="item.img" alt="" />
            <div>
              <div class="cloudName">{{ item.name }}</div>
              <div class="straight">
                <img src="@/assets/img/straight.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- 右边栏 -->
      <div class="apparatus">
        <div class="apparatusOne">
          
          <div class="apparatusOneLeft">
            <p class="timeBox">更新时间：{{ time }}</p>
            <chinaMapEchart class="chinaBox" id="china" :ehartData='chinaMapData'></chinaMapEchart>
          </div>
          <div class="apparatusOneRight">
            <shouyePie2Echart class="apparatusOneRight-item" :id="'pie2Echart'" :ehartData="pie2EchartData" />
          </div>
        </div>
        
        <!-- 各省仪器分布图 、 仪器总数 -->
        <div class="apparatusFirstline">
          <div class="apparatusLeft">
            <!-- <div class="types_changeBox">
              <el-tabs v-model="activeName" type="card"  @tab-click="handleClick">
                <el-tab-pane label="全部" name="all"></el-tab-pane>
                <el-tab-pane v-for="(item,index) in platform2province_info" :key="index" :label="item.name" :name="item.name"></el-tab-pane>
              </el-tabs>
            </div>
            <shouyeBarEchart :id="'lineEchart'" :ehartData="lineEchartData" /> -->
            <el-tabs v-model="activeName" type="card"  @tab-click="handleClick">
              <el-tab-pane label="全部" name="all">
                <shouyeBarEchart :id="'lineEchart'" v-if="activeName=='all'" :ehartData="lineEchartData" />
              </el-tab-pane>
              <el-tab-pane v-for="(item,index) in platform2province_info" :key="index" :label="item.name" :name="item.name" style="width:100%">
                <shouyeBarEchart :id="'lineEchart'" v-if="activeName==item.name" :ehartData="lineEchartData" />
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- <div class="apparatusRight">
            <shouyePie2Echart :id="'pie2Echart'" :ehartData="pie2EchartData" />
          </div> -->
        </div>

        <!--  云质控、近30天的试剂消耗量、试剂分布-->
        <div class="quality">
          <!-- 云质控、近30天的试剂消耗量 -->
          <div class="qualityLift">
            <!-- 云质控 -->
            <div class="qualityLifttop">
              <div class="qualityLifttopName">云质控</div>
              <div class="qualityLifttopicon">
                <div
                  class="qualityLifttopiconDiv"
                  v-for="(item, index) of listYzk"
                  :key="index"
                >
                  <div class="iconone">
                    <img
                      :src="require('@/assets/img/' + item.icon + '.png')"
                      alt=""
                    />
                    <div class="icontwo">
                      <div class="shuzi">{{ item.value?item.value:0 }}</div>
                      <div class="zongshu">{{ item.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 近30天的试剂消耗量 -->
            <div class="qualityLiftbottom">
              <shouyeLineEchart :id="'BarEchart'" :ehartData="barEchartData" />
            </div>
          </div>
          <!-- 试剂分布 -->
          <div class="qualityRight">
            <shouyePieEchart
              :id="'pieEchart'"
              :sum="reagent_dataSum"
              :ehartData="pieEchartDataX"
              :colorList="colorListPie"
            />
            <div
              class="agentia"
              v-for="(item, index) of pieEchartData"
              :key="index"
            >
              <div class="left">
                <div
                  class="bd"
                  :style="'background:' + colorListPie[index]"
                ></div>
                <div class="agentialeftone">{{ item.name }}</div>
              </div>
              <div class="right">
                <div class="div1">{{ item.rate }}%</div>
                <div class="agentiacentretwo">{{ $tool.moneyFormatter(item.value,0) }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 各省仪器分布图、 仪器用量Top10-->
        <div class="amount">
          <div class="amountLeft">
            <shouyeCategory
              :id="'shouyeCategory'"
              :ehartData="categoryEchartData"
            />
            <div id="mainAmount"></div>
          </div>
          <div class="amountRight">
            <shouyeProgressList
              :progressData="progressData"
              :topUsedMonth="topUsedMonth"
            ></shouyeProgressList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headerv from '@/components/headerv.vue'
import shouyeBarEchart from '@/components/shouyeBarEchart.vue'
import shouyeLineEchart from '@/components/shouyeLineEchart.vue'
import shouyePieEchart from '@/components/shouyePieEchart.vue'
import shouyeCategory from '@/components/shouyeCategory.vue'
import shouyeProgressList from '@/components/shouyeProgressList.vue'
import shouyePie2Echart from '@/components/shouyePie2Echart.vue'
import chinaMapEchart from '@/components/chinaMapEchart.vue'

import api from '@/api/shouye'
import {menuList} from '@/assets/js/menuList.js'
import {getmenuList}  from '@/router/menuMain.js'

export default {
  components: {
    Headerv,
    shouyeBarEchart,
    shouyeLineEchart,
    shouyePieEchart,
    shouyeCategory,
    shouyeProgressList,
    shouyePie2Echart,
    chinaMapEchart,
  },
  data() {
    return {
      // 左边导航条数组
      menuList: [],

      color: [
        '#F75151',
        '#FFC200',
        '#19CB63',
        '#166DC9',
        '#00AFE9',
        '#1ACB64',
        '#F77490',
        '#F9C942',
        '#2C52F1',
        '#0073CD',
      ],//颜色

      //各省仪器分布图-数据
      lineEchartData: {
        x:
        [
          "北京",
          "安徽",
          "福建",
          "甘肃",
          "广东",
          "广西",
          "贵州",
          "海南",
          "河北",
          "河南",
          "黑龙江",
          "湖北",
          "湖南",
          "吉林",
          "江苏",
          "江西",
          "辽宁",
          "内蒙古",
          "青海",
          "山东",
          "山西",
          "陕西",
          "上海",
          "四川",
          "天津",
          "西藏",
          "新疆",
          "云南",
          "浙江",
          "重庆"
        ],
        y:[
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0,
0
        ],
      },

      topUsedMonth: '2023-xx', //当前月份
      activeName: 'all',
      province_ins_info: '',
      platform2province_info: '',
      // 云质控
      listYzk: [
        {
          name: '省份总数',
          icon: 'provinces',
          value: '',
        },
        {
          name: '医院数量',
          icon: 'hospital',
          value: '',
        },
        {
          name: '仪器数量',
          icon: 'instrument',
          value: '',
        },
        {
          name: '失控次数',
          icon: 'control',
          value: '',
        },
      ],

      // 近30天的试剂消耗量
      barEchartData: {
    x: [
        "2023xx01",
        "2023xx02",
        "2023xx03",
        "2023xx04",
        "2023xx05",
        "2023xx06",
        "2023xx07",
        "2023xx08",
        "2023xx09",
        "2023xx10",
        "2023xx11",
        "2023xx12",
        "2023xx13",
        "2023xx14",
        "2023xx15",
        "2023xx16",
        "2023xx17",
        "2023xx18",
        "2023xx19",
        "2023xx20",
        "2023xx21",
        "2023xx22",
        "2023xx23",
        "2023xx24",
        "2023xx25",
        "2023xx26",
        "2023xx27",
        "2023xx28",
        "2023xx29",
        "2023xx30",
        "2023xx31",
    ],
    y : [
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000,
        50000
    ]
},

      // 试剂分布
      reagent_dataSum: 100,//试剂总数
      pieEchartDataX:[{ 
          name:'全部',
          value: 100 
        }],
      pieEchartData: [
        
      ],
      colorListPie: [
        "#0070BE",
        "#70309D",
        "#DE0001",
        '#00AFE9',
        '#1ACB64',
        '#F77490',
        '#F9C942',
        '#2C52F1',
      ],

      // 各省30天试剂使用分布图
      categoryEchartData: {
     x: [
        "江西",
        "山东",
        "甘肃",
        "河南",
        "四川",
        "江苏",
        "广东",
        "湖南",
        "上海",
        "浙江"
    ],
    y: [
        10000,
        20000,
        30000,
        40000,
        50000,
        60000,
        70000,
        80000,
        90000,
        100000,
    ]
},

      // 仪器用量Top10
      progressData:[
    {
        "a": "10Lxxx26/OTTOMAN-1000",
        "b": "茂名xx医院",
        "c": 100,
        "d": 10000,
        "color": "#F75151"
    },
    {
        "a": "10Nxxx02/OTTOMAN-1000",
        "b": "上海xx医院",
        "c": 95,
        "d": 9500,
        "color": "#FFC200"
    },
    {
        "a": "10Kxxx10/OTTOMAN-1000",
        "b": "大连xx医院",
        "c": 90,
        "d": 9000,
        "color": "#19CB63"
    },
    {
        "a": "10Lxxx08/OTTOMAN-1000",
        "b": "广西xx医院",
        "c": 85,
        "d": 8500,
        "color": "#166DC9"
    },
    {
        "a": "10Jxxx13/OTTOMAN-1000",
        "b": "温岭xx医院",
        "c": 80,
        "d": 8000,
        "color": "#00AFE9"
    },
    {
        "a": "10Nxxx03/OTTOMAN-1000",
        "b": "上海xx医院",
        "c": 75,
        "d": 7500,
        "color": "#1ACB64"
    },
    {
        "a": "10Lxxx24/OTTOMAN-1000",
        "b": "武威xx医院",
        "c": 70,
        "d": 7000,
        "color": "#F77490"
    },
    {
        "a": "19Nxxx02/OTTOMAN-2020",
        "b": "玉环xx医院",
        "c": 65,
        "d": 6500,
        "color": "#F9C942"
    },
    {
        "a": "19Nxxx10/OTTOMAN-2020",
        "b": "重庆xx医院",
        "c": 60,
        "d": 6000,
        "color": "#2C52F1"
    },
    {
        "a": "10Lxxx18/OTTOMAN-1000",
        "b": "浙江xx医院",
        "c": 55,
        "d": 5500,
        "color": "#0073CD"
    }
],

      // 仪器总数
      pie2EchartData: {
        instrument_sum: 1627,
        inner: [
          {
            value: 50,
            name: '离线终端',
            itemStyle: {
              color: '#166DC9',
            },
          },
          {
            value: 28,
            name: '在线终端',
            itemStyle: {
              color: '#1ACB64',
            },
          },
          {
            value: 22,
            name: '维修中',
            selected: true,
            itemStyle: {
              color: '#FF3370',
            },
          },
        ],
        out: [
          {
            name: 'MAYA-500',
            value: 800,
            itemStyle: {
              color: '#EE6666',
            },
          },
          {
            name: 'Ottoman-1000',
            value: 400,
            itemStyle: {
              color: '#73C0DE',
            },
          },
          {
            name: 'Ottoman-2020',
            value: 300,
            itemStyle: {
              color: '#50C38D',
            },
          },
          {
            name: 'mini+',
            value: 200,
            itemStyle: {
              color: '#FC8452',
            },
          },
          {
            name: 'Qpad',
            value: 100,
            itemStyle: {
              color: '#6460B4',
            },
          },
          {
            name: 'CycloneSun-1000',
            value: 100,
            itemStyle: {
              color: '#9A60B4',
            },
          },
          {
            name: 'MAYA-300',
            value: 80,
            itemStyle: {
              color: '#EA7CCC',
            },
          },
        ],
      },
      chinaMapData: [
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '北京',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '安徽',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '福建',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '甘肃',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '广东',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '广西',
          dosage_sum: 0,
        },

        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '贵州',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '海南',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '河北',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '河南',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '黑龙江',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '湖北',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '湖南',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '吉林',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '江苏',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '江西',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '辽宁',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '内蒙古',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '宁夏',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '青海',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '山东',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '山西',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '陕西',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '上海',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '四川',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '天津',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '西藏',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '新疆',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '云南',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '浙江',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '重庆',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '香港',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '澳门',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '台湾',
          dosage_sum: 0,
        },
        {
          hospital_cnt: 0,
          ins_cnt: 0,
          province_name: '南海诸岛',
          dosage_sum: 0,
        },
      ],
      time: '',
      user_name: '你好',
    }
  },
  mounted() {
    var self = this
    setInterval(function() {
      self.time = new Date().toLocaleString()
    }, 1000)
    this.menuList = getmenuList();
    this.indexFun()
    this.get_map_stats();
  },
  methods: {
    // 获取地图数据
    get_map_stats() {
      api.map_stats().then(res => {
        if(res.code==200) {
          this.chinaMapData = res.data
        }
      })
    },
    // 获取数据
    indexFun() {
      api.index({}).then((res) => {
        let data = res.data,
          cloud_qc_data = res.data.cloud_qc_data,
          province_ins_info = res.data.province_ins_info,
          platform2province_info = res.data.platform2province_info,
          used_day_list = res.data.used_day_list,
          province_used_list = res.data.province_used_list,
          top_used_list = res.data.top_used_list,
          platform_data = res.data.platform_data,
          top_used_max = res.data.top_used_list[0].cnt,
          topUsedMonth = res.data.top_used_month + '',
          reagent_dataSum = res.data.reagent_sum,
          reagent_data = res.data.reagent_data.map(e => {
            return {
              value: e.cnt,
              name: e.reagent_name,
            }
          })
        
        this.reagent_dataSum = reagent_dataSum;

        this.topUsedMonth = topUsedMonth.substr(0,4) + '-' + topUsedMonth.substr(4,2)


        this.user_name = data.user_name

        this.listYzk[0].value = cloud_qc_data.province__count
        this.listYzk[1].value = cloud_qc_data.hospital__count
        this.listYzk[2].value = cloud_qc_data.instrument__count
        this.listYzk[3].value = cloud_qc_data.cnt
        // 仪器总数
        this.pie2EchartData.instrument_sum = res.data.instrument_sum
        this.pie2EchartData.inner[0].value = res.data.leave_ter
        this.pie2EchartData.inner[1].value = res.data.online_ter
        this.pie2EchartData.inner[2].value = res.data.repair_cnt

        this.pie2EchartData.out = platform_data.map((e,index) => {
          return {
            name: e.platform_unique,
            value: e.cnt,
            itemStyle: {
              color: this.color[index],
            },
          }
        }).sort((O1,O2)=>{
          return O2.value-O1.value
        })

        // 仪器用量
        this.progressData = top_used_list.map((e,index) => {
          return  {
            a: `${e.instrument_id}/${e.platform_unique}`,
            b: e.hospital_name,
            c: e.cnt/top_used_max*100,
            d: e.cnt,
            color: this.color[index],
          }
        })
        // pieEchartData: [
        // {
        //   value: 45,
        //   name: '产品1',
        // },
        //试剂分布
        this.pieEchartData = reagent_data.map(e => {
          let nameArr = e.name.split('_')
          if(nameArr.length == 2){
            e.name = nameArr.join('-')
          }
          return {
            ...e,
            rate: (e.value/reagent_dataSum*100).toFixed(2)
          }
        })
        
        let otherSum = 0
        this.pieEchartDataX = [];
        this.pieEchartData.forEach(e => {
          if(e.rate>5){
            this.pieEchartDataX.push({value:e.value,name:e.name})
          }else{
            otherSum += e.value
          }
        })
        if(otherSum!=0){
          this.pieEchartDataX.push({
            value: otherSum,
            name: '其他',
          })
        }

        //省份30天用量
        this.categoryEchartData = {x:[],y:[]}
        province_used_list.forEach((element) => {
          this.categoryEchartData.x.push(element.province_name)
          this.categoryEchartData.y.push(element.cnt)
        })
        

        // 各省仪器数
        this.lineEchartData = {x:[],y:[]}
        province_ins_info.forEach((element) => {
          this.lineEchartData.x.push(element.province_name)
          this.lineEchartData.y.push(element.cnt)
        })
        this.province_ins_info = province_ins_info;
        this.platform2province_info = []
         platform2province_info;
        for(var a in platform2province_info){
          this.platform2province_info.push({
            name: a,
            list: platform2province_info[a]
          })
        }

        // 30天试剂消耗量
        this.barEchartData = {x:[],y:[]}
        used_day_list.forEach((element) => {
          this.barEchartData.x.push(element.stats_day)
          this.barEchartData.y.push(element.cnt)
        })

        
      })
    },
    // 切换项目类型标签
    handleClick(tab, event) {
     
      this.lineEchartData = {x:[],y:[]}
      if(this.activeName == 'all'){
        this.province_ins_info.forEach((element) => {
          this.lineEchartData.x.push(element.province_name)
          this.lineEchartData.y.push(element.cnt)
        })
        return
      }

      this.platform2province_info.find(e => e.name ==this.activeName).list.forEach((element) => {
        this.lineEchartData.x.push(element.province_name)
        this.lineEchartData.y.push(element.cnt)
      })
      // this.province_ins_info[this.activeName].list.forEach((element) => {
      //   this.lineEchartData.x.push(element.province_name)
      //   this.lineEchartData.y.push(element.cnt)
      // })
    },
    //左边栏 - 跳转其他页面
    toPage(value) {
      this.$router.push({ path: value.path?value.path:value.children[0].path })
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: px(280);
  background: url(~@/assets/img/setting.png) 100% no-repeat;
  overflow: hidden;
  .head {
    width: 100%;
    display: flex;
  }
}
.headTop {
  flex: 1;
  padding: px(31) px(40) px(39) px(31);
  .header-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-logo img {
    width: px(36);
    height: px(36);
  }
  .line {
    width: 1px;
    height: px(18);
    background-color: white;
    margin-left: px(5);
  }
  .designation {
    width: 560px;
    line-height: 28px;
    
    color: #fff;
    font-size: 18px;
    color: #ffffff;
    margin-left: px(20);
    text-align: left;
  }
}
.headMenu {
  display: flex;
  padding-left: px(20);
  .menuItem {
    background-color: #fff;
    margin-right: px(30);
    border-radius: px(5);
    width: px(200);
    .menuItem-box {
      padding: px(10);
      display: flex;
      .menuItem-name {
        color: #111111;
        margin-left: px(20);
      }
      .straight img {
        width: px(14);
        height: px(9);
        margin-left: px(20);
        margin-top: px(20);
      }
    }
    .menuItem-box img {
      width: px(60);
      height: px(60);
    }
  }
  
}
.header_right {
  margin-left: px(132);
  .avatar {
    margin-bottom: px(15);
  }
  .el-avatar {
    width: px(96);
    height: px(96);
  }
  .avatarname {
    font-size: px(20);
    font-weight: bold;
    color: #ffffff;
    margin-bottom: px(4);
    margin-left: px(18);
  }
  .notice {
    height: px(39);
    font-size: px(18);
    color: #ffffff;
    .noticeOne {
      margin-bottom: px(4);
    }
    .noticeTwo {
      margin-left: px(-60);
    }
  }
}
.apparatus {
  width: px(1860);
  height: px(1606);
  .apparatusOne {
    width: 100%;
    display: flex;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: px(20);
    .apparatusOneLeft {
      height: px(1000);
      width: 85%;
      //background-color: #efefef;
      margin-bottom: px(20);
      border-radius: 5px;
      position: relative;
      border-right: 1px solid #e6eaf4;
      .timeBox {
        position: absolute;
        top: px(120);
        left: 40px;
      }
      .chinaBox {
        width: 100%;
        height: 100%;
      }
    }
    .apparatusOneRight {
      width: 15%;
      position: relative;
      .apparatusOneRight-item {
        position: absolute;
        top: px(110);
      }
    }
  }
 
  .apparatusFirstline {
    height: px(448);
    background: #ffff;
    border-radius: 5px;
    display: flex;
    margin-bottom: px(20);
    .apparatusLeft {
      width: 100%;
      height: 100%;
      border-right: 1px solid #e6eaf4ff;
      position: relative;
      ::v-deep .el-tabs__header{
        margin-bottom: 0;
      }
      .types_changeBox {
        position: absolute;
        top: px(10);
        right: 10%;
        ::v-deep .el-tabs__nav {
          height: px(40);
          .el-tabs__item {
            padding: 0 px(8);
            line-height: px(40);
            font-size: px(16);
          }
        }
      }
    }
    .apparatusRight {
      width: px(487);
      height: 100%;
    }
  }
  .quality {
    width: 100%;
    height: px(666);
    display: flex;
    margin-bottom: px(20);
    .qualityLift {
      width: px(1022);
      height: px(666);
      .qualityLifttop {
        width: px(1022);
        height: px(200);
        background: #fff;
        margin-bottom: px(20);
        .qualityLifttopName {
          font-size: px(24);
          font-weight: bold;
          padding-top: px(20);
          padding-left: px(20);
          margin-bottom: px(26);
        }
        .qualityLifttopicon {
          width: px(982);
          height: px(104);
          margin-left: px(20);
          display: flex;
          justify-content: space-between;
          .shuzi {
            font-size: px(28);
            font-weight: bold;
            color: #203152;
          }
          .shuzi1 {
            font-size: px(28);
            font-weight: bold;
            color: #f1734eff;
          }
          .zongshu {
            font-size: px(18);
            color: #adb3be;
          }
          .iconone {
            display: flex;
            margin-left: px(20);
            margin-top: px(27);
          }
          .icontwo {
            margin-left: px(16);
          }
          .qualityLifttopiconDiv {
            width: px(238);
            height: px(104);
            background-color: #eff4fbff;
            border-radius: 2px;
            img {
              width: px(58);
              height: px(58);
            }
          }
        }
      }
      .qualityLiftbottom {
        width: px(1022);
        height: px(446);
        background: #fff;
      }
    }
    .qualityRight {
      width: 100%;
      height: px(666);
      overflow-y: auto;
      margin-left: px(20);
      background: #fff;
      .agentia {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 px(20);
        height: px(43);
        color: #333333;
        border-bottom: px(1) solid #ebf0f6;
        box-sizing: border-box;
        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .bd {
            width: px(10);
            height: px(10);
            border-radius: px(8);
            margin-right: px(5);
          }
          .agentialeftone {
            font-size: px(18);
            color: #506a8c;
          }
        }
        .right {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-weight: bold;
          color: #333333;
          font-size: px(18);
          .agentiacentretwo {
            width: px(120);
            text-align: right;
          }
        }
      }
      .agentia:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }
  .amount {
    width: 100%;
    height: px(437);
    display: flex;
    .amountLeft {
      width: 50%;
      height: px(437);
      background-color: #fff;
    }
    .amountRight {
      margin-left: px(20);
      width: 50%;
      height: px(437);
      background-color: #fff;
    }
  }
}

.below {
  height: px(2650);
  padding: px(30);
  background-color: #eff4fbff;
}
.searchBar {
  height: px(1340);
  width: px(280);
  position: relative;
  top: px(-80);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.searchBarone {
  height: px(100);
  width: px(200);
  display: flex;
  background-color: #fff;
  margin-bottom: px(20);
  .cloud {
    .straight img {
      width: px(14);
      height: px(9);
      margin-left: px(20);
      margin-top: px(20);
    }
  }
  .cloud img {
    width: px(60);
    height: px(60);

    
   
  }
 
}
//.searchBarone {
//  width: px(200);
//  height: px(90);
//  background-color: #fff;
//  margin: 0 auto;
//  margin-bottom: px(20);
//  .cloud {
//    display: flex;
//    padding-left: px(10);
//    padding-top: px(10);
//    .cloudName {
//      color: #111111;
//      margin-left: px(20);
//    }
//    .straight img {
//      width: px(14);
//      height: px(9);
//      wmargin-left: px(20);
//      margin-top: px(20);
//    }
//  }
//  .cloud img {
//    width: px(60);
//    height: px(60);
//  }
//}
</style>
